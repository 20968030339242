import React from "react"
import Header from "../../components/Header"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import FluidImage from "../../components/FluidImage"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import CategoriesWidget from "../../components/categoriesWidget"
import LookingFor from "../../components/LookingFor"
import { decode } from 'he'


const Post = ({ pageContext }) => {
  const {
    post: { title, content, slug, featuredImage,customNewsDetails,seo },
    breadcrumb: { crumbs },
  } = pageContext

  const isPartiallyActive = ({ isPartiallyCurrent, isCurrent }) => {
    return isPartiallyCurrent && isCurrent
      ? { className: 'breadcrumb__link breadcrumb__link__active' }
      : {}
  }

  const newCrumbs = crumbs.map(item => {

    const container = {};
    if(item.pathname=='/blog/'){
      container.pathname="/news-and-insights/";
      container.crumbLabel = decode(item.crumbLabel.replace(/-/g, ' '));
    }
    else{
      container.crumbLabel = decode(item.crumbLabel.replace(/-/g, ' '));
      container.pathname = item.pathname;
    }



    return container;
  })

  return (
    <>
    <Header siteTitle={title} />
    <SEO title={seo.title} description={seo.metaDesc}/>
    <div className="single-post">
    <main className="wrapper">
    <section className="content">
    <div className="breadcrumbs">
    <Breadcrumb
            crumbs={newCrumbs}
            crumbSeparator=" / "
            crumbLabel={decode(title)}
            getProps={isPartiallyActive}
          />
    </div>
    <Layout>


      <FluidImage image={featuredImage} style={{ marginBottom: "15px" }}/>

      <section className="content">
  <div className="eighty-spacer"></div>
   <div className="content-block clearfix">

      <div className="news-single-centered-block">
        <h1 dangerouslySetInnerHTML={{ __html: title }}/>
        <h2>{customNewsDetails.customSubHeading}</h2>
        <div className="author-field">
               {customNewsDetails.customAuthorField}        </div>
               </div>
      <div className="thirty-spacer"></div>
   </div>
</section>


      <section className="content">
      <div className="content-block clearfix">
          <div className="news-single-content" dangerouslySetInnerHTML={{ __html: content }}/>
              <div className="social-share">
              <span>
              Share
              </span>
              <ul>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.htb.co.uk/blog/${slug}`} target="_blank" rel="noopener noreferrer"><li className="share-facebook"></li></a>
              <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.htb.co.uk/blog/${slug}`} target="_blank" rel="noopener noreferrer"><li className="share-linkedin"></li></a>
              <a href={`http://twitter.com/share?text=${decode(title)}&amp;url=https://www.htb.co.uk/blog/${slug}`} target="_blank" rel="noopener noreferrer"><li className="share-twitter"></li></a>
              </ul>
              </div>
          </div>
          <div className="eighty-spacer"></div>
      </section>
      <LookingFor />
      <CategoriesWidget />
    </Layout>
    </section>
    </main>
    </div>
    </>
  )
}

export default Post
